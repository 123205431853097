import Background from '../../components/background/background';
import MainCard from '../../components/main-card/main-card';

const Home = () => {
  return (
    <div>
      <Background />
      <MainCard />
    </div>
  );
};

export default Home;
