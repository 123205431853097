import { gradient } from '../../styles/keyframes';
import { Box } from '@mui/material';

function Background() {
  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(145deg, #121a2f, #121a2f, #4db4d3, #121a2f, #121a2f)`,
        backgroundSize: '400% 400%',
        animation: `${gradient} 15s ease infinite`,
        position: 'fixed',
        height: '100vh',
        width: '100%',
        zIndex: '-1',
      }}
    ></Box>
  );
}

export default Background;
