import { Avatar, Box, Typography } from "@mui/material";
import profile from "../../images/profile.jpg";
import logo from "../../images/new-logo.png";
import React from "react";

function Profile() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      marginBottom={3}
    >
      <Avatar
        sx={{ height: 150, width: 150, display: "flex", marginBottom: 2 }}
        src={profile}
      />
      <img
        src={logo}
        alt="logo"
        style={{ width: "200px", display: "flex", marginBottom: 16 }}
      />

      <Typography color="white" fontWeight="bold">
        @dramer.music
      </Typography>
      <Typography color="white">SHOWS / TICKETS / DJ SETS / SOCIALS</Typography>
    </Box>
  );
}

export default Profile;
