import { Box } from '@mui/material';
import React from 'react';

interface Props {
  src: string;
  title: string;
}

const VideoCard = ({ src, title }: Props) => {
  return (
    <Box mb={2} sx={{ borderRadius: 2, overflow: 'hidden', boxShadow: 5 }}>
      <iframe
        width='100%'
        height='315'
        src={`https://www.youtube.com/embed/${src}?modestbranding=1`}
        title={title}
        style={{
          border: 'none',
          position: 'relative',
          top: 3,
          left: 3,
          transform: 'scale(1.03)',
        }}
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        allowFullScreen
      ></iframe>
    </Box>
  );
};

export default VideoCard;
