export const data = [
  {
    text: 'Melodic Techno DJ SET',
    link: 'https://www.youtube.com/watch?v=Av9nWuVfRtU&',
  },
  {
    text: 'Progressive House DJ SET',
    link: 'https://www.youtube.com/watch?v=tVfq1Ev_Sv8&',
  },
  {
    text: 'Youtube Channel',
    link: 'https://www.youtube.com/dramer',
  },
  {
    text: 'Instagram',
    link: 'https://www.instagram.com/dramer.music/',
  },
  {
    text: 'Spotify',
    link: 'https://open.spotify.com/artist/5EkkFSrH5KA7WQwP8nCmfw?si=2whKhPexTG2W_QEqwF4BrQ',
  },
];

export const djSets = [
  {
    title: 'Rock Nacional DJ Set',
    src: 'KI5BVz2hnJE',
  },
  {
    title: 'Soda Stereo y Gustavo Cerati - DJ Set Tributo - Progressive House',
    src: 'VZ-aTLQLJ5E',
  },
  {
    title: 'YearMix 2023 - Progressive House & Melodic Techno',
    src: 'A2nmfAQC4Co',
  },
  {
    title: 'Progressive House Domo DJ Set',
    src: 'qsdaMNK5PxE',
  },
  {
    title: 'Depeche Mode - Tribute DJ Set - Progressive House & Melodic Techno',
    src: 'aU-PI0SbKis',
  },
];

export const tracks = [
  {
    title: 'Soda Stereo - Nuestra Fé (Dramer Remix)',
    src: 'SAOQFoWg8Tk',
  },
  {
    title: 'Trip To Mars',
    src: 'iOY-xd0s29g',
  },
  {
    title: 'Buenos Aires',
    src: 'u0B1ziqXCac',
  },
  // {
  //   title: 'Bariloche',
  //   src: 'LhC_VWpp7Kw',
  // },
  // {
  //   title: 'Hysteria',
  //   src: 'ZWQHtuw6nTk',
  // },
];
