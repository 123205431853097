import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import img from '../../images/005.jpg';
import React from 'react';

function SocialMedias() {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='end'
      flexDirection={'column'}
      marginTop={4}
      marginBottom={4}
      paddingY={20}
      borderRadius={2}
      sx={{
        backgroundImage: `url(${img})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.4)',
      }}
    >
      <Typography fontSize={22} marginTop={8} fontWeight='bold' color='white'>
        let's keep in touch
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />

      <Box>
        <IconButton size='large' href='https://www.instagram.com/dramer.music/'>
          <FontAwesomeIcon size='lg' icon={brands('instagram')} />
        </IconButton>
        <IconButton size='large' href='https://www.youtube.com/dramer'>
          <FontAwesomeIcon size='lg' icon={brands('youtube')} />
        </IconButton>
        <IconButton size='large' href='https://www.tiktok.com/@dramer.music'>
          <FontAwesomeIcon size='lg' icon={brands('tiktok')} />
        </IconButton>
        <IconButton
          size='large'
          href='https://open.spotify.com/artist/5EkkFSrH5KA7WQwP8nCmfw?si=2whKhPexTG2W_QEqwF4BrQ'
        >
          <FontAwesomeIcon size='lg' icon={brands('spotify')} />
        </IconButton>
      </Box>
    </Box>
  );
}

export default SocialMedias;
