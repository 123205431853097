import { keyframes } from '@mui/material';

export const gradient = keyframes`
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
`;

export const rotate = keyframes`
    0% {
      transform: rotate(0deg);
      }
    100% {
      transform:rotate(30deg);
    }
`;