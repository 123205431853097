import { Typography, Divider } from '@mui/material';
import VideoCard from '../video-card/video-card';
import { Box } from '@mui/system';
import React from 'react';

interface Props {
  title: string;
  videos: { src: string; title: string }[];
}

const VideoSection = ({ title, videos }: Props) => {
  return (
    <Box mb={8}>
      <Typography fontSize={22} fontWeight='bold' color='white'>
        {title}
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />

      {videos.map((item, i) => (
        <VideoCard key={i} src={item.src} title={item.title} />
      ))}
    </Box>
  );
};

export default VideoSection;
