import { Typography, Divider } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const SoundcloudSection = () => {
  return (
    <Box mb={8}>
      <Typography fontSize={22} fontWeight='bold' color='white'>
        SoundCloud
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />
      <iframe
        width='100%'
        height='450'
        scrolling='no'
        style={{ border: 'none' }}
        allow='autoplay'
        src='https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1584659944&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=true'
      ></iframe>
      <div
        style={{
          fontSize: '10px',
          color: '#cccccc',
          lineBreak: 'anywhere',
          wordBreak: 'normal',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          fontFamily:
            'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif',
          fontWeight: 100,
        }}
      >
        <a
          href='https://soundcloud.com/dramermusic'
          title='DRAMER'
          target='_blank'
          style={{ color: '#cccccc', textDecoration: 'none' }}
        >
          DRAMER
        </a>{' '}
        ·{' '}
        
        <a
          href='https://soundcloud.com/dramermusic/sets/dj-sets'
          title='DJ Sets'
          target='_blank'
          style={{ color: '#cccccc', textDecoration: 'none' }}
        >
          DJ Sets
        </a>
      </div>
    </Box>
  );
};

export default SoundcloudSection;
