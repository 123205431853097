import Background from "../../components/background/background";
import { Box, Container } from "@mui/material";
import Profile from "../../components/profile/profile";
import ButtonLink from "../../components/button-link/button-link";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands } from "@fortawesome/fontawesome-svg-core/import.macro";

export const linksData = [
  {
    text: "ENTRADAS LA TANGENTE - 23.8",
    link: "https://tickets.latangente.com.ar/fiesta/dramer-1",
    icon: <LocalActivityIcon />,
  },
  {
    text: "ROCK NACIONAL DJ SET",
    link: "https://www.youtube.com/watch?v=KI5BVz2hnJE",
    icon: <FontAwesomeIcon size="lg" icon={brands("youtube")} />,
  },
  {
    text: "YOUTUBE",
    link: "https://www.youtube.com/dramer",
    icon: <FontAwesomeIcon size="lg" icon={brands("youtube")} />,
  },
  {
    text: "INSTAGRAM",
    link: "https://www.instagram.com/dramer.music/",
    icon: <FontAwesomeIcon size="lg" icon={brands("instagram")} />,
  },
  {
    text: "SPOTIFY",
    link: "https://open.spotify.com/artist/5EkkFSrH5KA7WQwP8nCmfw?si=2whKhPexTG2W_QEqwF4BrQ",
    icon: <FontAwesomeIcon size="lg" icon={brands("spotify")} />,
  },
  {
    text: "SOUNDCLOUD",
    link: "https://soundcloud.com/dramermusic",
    icon: <FontAwesomeIcon size="lg" icon={brands("soundcloud")} />,
  },
  {
    text: "TIKTOK",
    link: "https://www.tiktok.com/@dramer.music",
    icon: <FontAwesomeIcon size="lg" icon={brands("tiktok")} />,
  },
];

const Links = () => {
  return (
    <div>
      <Background />
      <Container maxWidth="sm">
        <Box
          display="flex"
          minHeight="100vh"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Box
            sx={{
              width: "100%",
              padding: 2,
            }}
          >
            <Profile />
          </Box>

          <Box width={"100%"} marginBottom={10}>
            {linksData.map((link, index) => (
              <ButtonLink
                key={index}
                icon={link.icon}
                text={link.text}
                link={link.link}
              />
            ))}
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default Links;
