import React from "react";
import { Box, Button } from "@mui/material";
import { theme } from "../../styles/material-theme"; // Asegúrate de que el path es correcto

interface IButtonLink {
  text: string;
  link: string;
  icon: React.ReactNode; // Ajusta la interfaz para incluir el ícono
}

const ButtonLink = ({ text, link, icon }: IButtonLink) => {
  return (
    <Box paddingY={1}>
      <Button
        variant="outlined"
        color="success"
        fullWidth
        size="large"
        href={link}
        target="_blank"
        sx={{
          position: "relative",
          paddingY: 2,
          paddingLeft: 6,
          backgroundImage: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          borderRadius: 2,
          color: "#fff",
          fontWeight: "bold",
        }}
      >
        <Box sx={{ position: "absolute", left: 16, top: 16 }}>{icon}</Box>
        <Box sx={{ margin: "0 auto" }}>{text}</Box>
      </Button>
    </Box>
  );
};

export default ButtonLink;
