import { Box, Divider, Typography } from '@mui/material';
import React from 'react';

const SpotifySection = () => {
  return (
    <Box>
      <Typography fontSize={22} fontWeight='bold' color='white'>
        Spotify
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />
      <iframe
        style={{ borderRadius: 12, border: 'none' }}
        src='https://open.spotify.com/embed/artist/5EkkFSrH5KA7WQwP8nCmfw?utm_source=generator'
        width='100%'
        height='352'
        allowFullScreen={false}
        allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'
        loading='lazy'
      ></iframe>
    </Box>
  );
};

export default SpotifySection;
