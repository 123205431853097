import SoundcloudSection from '../soundcloud-section/souncloud-section';
import SpotifySection from '../spotify-section/spotify-section';
import SocialMedias from '../social-medias/social-medias';
import VideoSection from '../video-section/video-section';
import { Box, Container } from '@mui/material';
import Profile from '../profile/profile';
import { djSets, tracks } from './info';
import React from 'react';

function MainCard() {
  return (
    <Container maxWidth='sm'>
      <Box
        display='flex'
        minHeight='100vh'
        alignItems='center'
        justifyContent='center'
      >
        <Box
          sx={{
            width: '100%',
            padding: 2,
          }}
        >
          <Profile />

          <VideoSection title={'DJ Sets'} videos={djSets} />

          <VideoSection title={'Tracks & Remixes'} videos={tracks} />

          <SoundcloudSection />

          <SpotifySection />

          <SocialMedias />

        </Box>
      </Box>
    </Container>
  );
}

export default MainCard;
